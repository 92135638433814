import { documentoStore } from './../../../store/modules/documentos/index';
import { defineComponent } from 'vue';
import DataGridFactory from '../../datagrid-factory/index.vue'
import { DxColumn, DxExport } from 'devextreme-vue/ui/data-grid';

export default defineComponent({
  name: 'XmlRecebidos',
  setup(){
    const store = documentoStore();
    
    return {
      store
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport
  }
})