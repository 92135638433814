<template>
  <DataGridFactory
    :dataSource="store.DocListaRecebimentoXml"
    :enabledActions="false"
    :nameToExport="'Xmls Recebibas'"
  >
    <template v-slot:column>
      <DxColumn
        data-field="dataRecebimento"
        data-type="date"
        caption="Data Recebimento"
      />
      <DxColumn
        data-field="protocoloRecebimento"
        alignment="right"
        caption="Protocolo"
      />
      <DxColumn data-field="processado" caption="Processado" />
      <DxColumn
        data-field="processamento"
        data-type="date"
        caption="Data Processamento"
      />
      <DxColumn data-field="descricao" caption="Arquivo" />
    </template>
  </DataGridFactory>
</template>
<script src="./index.ts"></script>